.StatsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: white;
  background: linear-gradient(226deg, #0a535f, #06304b);

  padding: 30px 5px;

  .text {
    display: flex;
    // align-items: none;
    text-align: center;

    .container{

      padding: 15px;
      text-align: left;
      

      h1{
        margin: 0;
        padding: 0;
  
        font-size: 16px;
        font-weight: 600;
        min-width: 450px;
        max-width: 400px;
        
        @media screen and (max-width: 900px) {
          min-width: 0;
          max-width: 100%;

        }
      }
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 10px;
    }

    h1 {
      margin: 0;
      padding: 0;

      font-size: 24px;
      font-weight: 300;
      min-width: 450px;
      max-width: 500px;
      
      @media screen and (max-width: 900px) {
        min-width: 0;
      }
    }

    p {
      font-weight: 300;
      font-size: 14px;
      max-width: 400px;

      @media screen and (max-width: 900px) {
        max-width: 800px;
      }
    }
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    margin-bottom: 15px;

  }
  
  .stat {
    display: flex;
    min-width: 200px;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 900px) {
      animation-name: statFadeIn;
      animation-duration: 1s;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.3s;
      }

      &:nth-child(3) {
        animation-delay: 0.5s;
      }

      &:nth-child(4) {
        animation-delay: 0.7s;
      }
    }

    img {
      width: 40px;
      height: 60px;
      margin-top: 10px;
      margin-right: 5px;
    }

    h2 {
      white-space: nowrap;
      position: relative;
      margin: 0;
      padding: 0;
      font-weight: 100;
      font-size: 28px;
    }

    span {
      white-space: nowrap;
      position: absolute;
      bottom: -15px;
      right: 0;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

@keyframes statFadeIn {
  from {transform: scale(0); filter: blur(10px); opacity: 0;}
  to {transform: scale(1); filter: blur(0); opacity: 1;}
}