.Container {
  max-width: 1100px;
  opacity: 0;

  animation-name: reveal;
  animation-delay: 1s;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  .content {
    background-color: white;
  }
}

iframe {
  padding-top: 30px;
  border: 0;
  width: 100%;
  height: 2450px;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    height: 2350px;

  }
  @media screen and (max-width: 950px) {
    height: 2150px;

  }

  @media screen and (max-width: 826px) {
    height: 5200px;

  }
  @media screen and (max-width: 600px) {
    height: 4300px;

  }
  @media screen and (max-width: 550px) {
    height: 4000px;

  }
  @media screen and (max-width: 500px) {
    height: 3800px;

  }
  @media screen and (max-width: 450px) {
    height: 3550px;

  }
  @media screen and (max-width: 425px) {
    height: 3250px;

  }
  @media screen and (max-width: 375px) {
    height: 2900px;

  }
  @media screen and (max-width: 320px) {
    height: 2800px;

  }

}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
