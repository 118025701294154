.Contact {
  h2 {
    margin: 0;
  }

  iframe {
    border: 0;
    width: 0;
    height: 0;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    font-size: 14px;
    margin-top: 10px;
  }

  input,
  textarea {
    border: 1px solid #EAEAEA;
    height: 30px;
    margin-bottom: 10px;
    font-size: 12px;
    padding: 5px;
    box-sizing: border-box;
    font-family: 'Montserrat', 'Trebuchet MS', sans-serif;
  }

  textarea {
    flex: 1;
    resize: none;
  }

  .rodal-dialog:before {
    content: 'E-mail sent!';

    padding-top: 15%;
    box-sizing: border-box;

    background-image: url('./mail.svg');
    background-size: 20% auto;
    background-repeat: no-repeat;
    background-position: center 40%;

    position: absolute;
    top: 0px;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;

    font-size: 30px;
    font-weight: 400;

    width: 100%;
    height: 100%;

    background-color: white;

    transition: 0.4s transform, 0.4s opacity;
    opacity: 0;
    transform: scale(0);
  }

  &.sent .rodal-dialog:before {
    transform: scale(1);
    opacity: 1;
  }
}