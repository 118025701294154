@import 'styles/colors.scss';

.Tracks {
  background-color: $secondary;
  color: white;
  padding: 20px 0;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  h1 {
    font-weight: 400;
  }

  p {
    max-width: 500px;
    margin: 0;
  }

  .list {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;

      .element {
        width: 90%;
      }
    }
  }

  .element {
    background-color: white;

    display: flex;
    align-items: center;

    color: #333;
    text-align: left;

    margin: 5px;
    padding: 15px;
    box-sizing: border-box;
    width: 45%;

    overflow: hidden;

    h2 {
      margin: 0;
    }

    img {
      margin-right: 15px;
      max-height: 100px;
      max-width: 200px;
    }

    .trackLogo {
      width: 80px;
      height: 80px;
    }

    button {
      margin: 5px 0;
    }


    @media screen and (max-width: 900px) {
      padding: 10px;
      align-items: flex-start;

      h2 {
        margin: 10px 0;
      }

      p {
        font-size: 14px;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: left; //justify-content: space-between;
      margin: 5px 0;

      button {
        display: none;
      }

      .Partner img {
        max-width: none;
        height: 50px;
        margin-left: 15px;
      }

      span {
        // margin-left: auto;
        font-weight: 300;
      }

      @media screen and (max-width: 900px) {
        flex-wrap: wrap;

        span {
          font-size: 12px;
        }

        img {
          max-width: 75px;
          max-height: 35px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
