@import 'styles/colors.scss';

.Footer {
  display: flex;
  align-items: center;
  padding: 15px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }


  .social-block {
    width: 200px;
    .logo {
      width: 200px;
    }

    .social-icons {
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }

  .lists-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (min-width: 900px) {
      margin-left: auto;
    }
  }

  ul {
    list-style-type: none;
    font-size: 12px;
    color: white;

    @media screen and (max-width: 900px) {
      width: 180px;
    }

    li:not(:first-child) {
      cursor: pointer;
      color: $gray;
    }

    a {
      color: $gray;
      text-decoration: none;
    }
  }
}