.Tech {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .imgwrap {
    width: 200px;
    margin: 0 auto;
    display: flex;
  }

  .contentimg {
    height: 70px;
    margin: 0 auto;
  }

  .CitySelector {
    text-transform: capitalize;
  }

  .Partner img {
    height: 120px;
    min-width: 120px;
    max-width: none;

    margin: 30px;
  }
}
