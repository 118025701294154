  .NotFound {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;

    background-image: url('./10K_Feet.jpg');
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 800px) {
      height: 400px;
    }

    .content,
    video {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      margin: 0;
      padding: 0;
    }

    .content {
      background-color: transparent;
      z-index: 1;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }


    video {
      object-fit: cover;
    }

    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
      color: white;
      text-shadow: 0 0 15px rgba(black, 0.5);
      user-select: none;
      text-align: center;
    }

    h1 {
      font-size: 200px;

      @media screen and (max-width: 800px) {
        font-size: 100px;
      }
    }

    h2 {
      font-weight: 200;
      font-size: 50px;

      @media screen and (max-width: 800px) {
        font-size: 30px;
      }
    }

    h3 {
      margin-top: 25%;
      text-transform: uppercase;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
