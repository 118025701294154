.Collaborators {
  header {
    display: flex;
    text-align: center;
    align-items: center;

    padding: 15px 0;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    h1 {
      font-weight: 300;
      margin: 0;
      padding: 0;
    }

    p {
      max-width: 500px;
      font-size: 14px;
    }
  }

  .text {
    padding-left: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .filter {
    flex: 1;
    margin: 5px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }

    img {
      height: 20px;
      padding-top: 5px;
    }

    > div {
      display: flex;
      align-items: flex-start;
      padding-left: 20px;
    }

    li {
      margin: 0 5px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid rgba(black, 0);
      color: rgba(black, 0.9);
      padding: 2px 5px;
      transition: 0.4s all;
    }

    li:hover,
    li.active {
      border: 1px solid rgba(black, 0.5);
      color: rgba(black, 1);
    }
  }


  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &:not(:last-child) {
      border-bottom: 1px solid #CCC;
    }

    .Person {
      margin: 10px;
    }
  }
}
