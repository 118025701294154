.Partner {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    
    @media screen and (max-width: 520px) {
      width: 50px;
      height: 50px;
    }
  }
}