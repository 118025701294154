.Person {
  width: 150px;
  position: relative;

  &.grayscale img {
    filter: grayscale(100%);
  }

  img {
    width: 100%;

    height: 150px;
    object-fit: cover;
    font-family: 'object-fit: cover;'
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 12px;

    &:last-child {
      margin-top: 10px;
      text-align: justify;
    }
  }


  @-moz-document url-prefix() {
    h2 {
      font-weight: 400;
    }

    p {
      font-weight: 300;
    }
  }

  .linkedin {
    width: 100%;
    height: 150px;
    position: absolute;
    background-color: rgba(white, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s all;
    transform: scale(0);
    border-radius: 150px;
    opacity: 0;

    img {
      width: 50%;
      height: 50%;
      opacity: 0.7;
    }
  }

  &:hover .linkedin {
    border-radius: 0;
    opacity: 1;
    transform: scale(1);
    background-color: rgba(white, 0.8);
  }

  @media screen and (max-width: 800px) {
    .linkedin {
      width: 100%;
      height: 150px;
      transform: scale(1);
      opacity: 1;
      top: 100px;
      right: 0;

      justify-content: flex-end;

      img {
        width: 25px;
        height: 25px;
        margin-top: -90px;

        background-color: rgba(white, 0.8);
        padding: 10px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        object-fit: contain;
        transition: 0.4s padding;
      }
    }

    &:hover .linkedin {
      background-color: transparent;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;

      img {
        padding-right: 30px;
      }
    }
  }
}
