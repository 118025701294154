.NewTimeline {
  background-color: #018B9D;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  >h1 {
    margin: 0;
    padding: 20px;
    text-align: center;
    font-size: 21px;
    font-weight: 200;
    color: white;
  }
  
  .items {
    width: 100%;
    background-color: #018B9D;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    width: 850px;
    padding-bottom: 30px;
    
    margin: 10px;
    position: relative;
    z-index: 2;

    p {
      width: 80%;
      margin-left: 10%;
      padding: 0;
    }
    
    .borderSide,
    .borderTop {
      position: absolute;
      z-index: 0;
    }

    .borderTop {
      bottom: 0;
      width: 90%;
    }

    .borderSide {
      height: 100%;
      left: 0px;
    }

    &:not(:first-child) {
      margin-top: -60px;
    }

    
    &:nth-child(even) {
      p {
        margin-left: 10%;
        text-align: right;
      }

      .icon {
        margin-right: 0;
        flex-direction: row-reverse;
        margin-left: auto;
      }
    
      .borderSide {
        left: auto;
        right: -5px;
        top: 0;
      }
    }
    
    .icon {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      text-align: left;
      margin-right: auto;
      
      img {
        width: 80px;
        height: 80px;
        margin: 10px;
      }

      h1,
      h2 {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 14px;
      }

      h1 {
        text-transform: uppercase;
      }
      
      h2 {
        font-size: 14px;
        font-style: italic;
        font-weight: 300;
      }
    }

    
    @media screen and (max-width: 700px) {
      width: 100%;
      margin: 0;

      .borderSide, 
      .borderTop  {
        display: none;
      }

      &:nth-child(odd),
      &:nth-child(even) {
        .icon {
          margin: 0;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        margin: 5px;

        p {
          text-align: center;
          width: 100%;
          margin: 0;
          padding: 10px;
          box-sizing: border-box;
        }
      }

      iframe {
        width: 100vw;
        height: 300px;
      }
    }

    

    iframe {
      width: 100%;
      
      margin: 0;
    }

  }
  

  button {
    border: 1px solid white;
  }

  .content {
    background-color: transparent;
  }

  p {
    font-size: 14px;
    color: white;
  }

  .cityDates {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;

    .cityDate {
      text-align: center;
      padding: 10px;

      h1,
      h2 {
        margin: 0;
        padding: 0;
        color: white;
        font-size: 14px;
      }

      h1 {
        text-transform: uppercase;
        color: rgba(white, 0.5);
        font-size: 18px;
      }

      h2 {
        font-weight: 200;
        margin-top: -5px;
      }
    }
  }

  .guides {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 30px;

    span {
      font-size: 14px;
      font-weight: 800;
      color: rgba(white, 0.5);
    }
  }

  iframe {
    width: 400px;
    height: 250px;
    margin-left: 10%;
  }
}