@import 'styles/colors.scss';

.TeamBox {
  position: relative;
  width: 280px;
  height: 150px;

  color: white;
  background-color: white;

  background-image: url('./img/team.svg');
  background-size: cover;
  background-position: center;

  .bg {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    z-index: 0;
    left: 0;
  }

  .best {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 5px;
    z-index: 1;
    background-color: rgba(orange, 0.8);
  }

  .title {
    width: 40%;
    height: 100%;
    display: flex;

    position: relative;
    z-index: 1;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-color: rgba($primary, 0.8);
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  .logo {
    width: 60px;
    height: 60px;

    object-fit: contain;

    font-family: 'object-fit: contain;';

    background-color: rgba(white, 0.9);
    border-radius: 20px;
    padding: 5px;
  }

  .track {
    width: 50px;
    height: 50px;
    padding: 5px;
    position: absolute;

    bottom: 0;
    right: 0;

    background-color: rgba($primary, 0.8);
  }
}