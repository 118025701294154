.RegisterNow-container {
  background-color: #018b9d;

  .RegisterNow-contents {
    height: 100%;

    padding: 15px;

    .largeTitle {
      display: flex;
      margin: 10px auto;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      button {
        border: 2px solid white;
      }
    }

    #title {
      text-align: center;
      color: white;
      padding-top: 15px;
      margin-top: 0px;

      @media screen and (max-width: 900px) {
        font-size: 1.25rem;
      }
    }
    #list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: auto;
      width: 95%;

      @media screen and (max-width: 900px) {
        flex-direction: column;
        text-align: center;
        width: 90%;
      }

      button {
        border: 1px solid white;

        padding: 10px 10px;
        font-size: 15px;
        @media screen and (max-width: 900px) {
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    #note {
      color: white;
      text-align: center;
      font-size: 1rem;
      padding: 15px;
      margin: 0px;
    }
  }
}

