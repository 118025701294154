.ProgramTimeline {
  padding: 20px 0;
  h1 {
    font-size: 28px;
    font-weight: 400;
    text-align: center;
  }

  p {
    text-align: center;
    padding: 0 15px;
  }

  .timeline {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  $linkWidth: 2px;
  $linkHeight: 20px;

  .link {
    display: none;

    @media screen and (max-width: 900px) {
      display: block;
      width: 65%;
      height: $linkWidth;
      background-color: #333;
      margin: 10px auto;

      &:before,
      &:after {
        content: '';
        display: block;
        width: $linkWidth;
        height: $linkHeight;
        background-color: #333;
      }

      &:before {
        margin-top: -$linkHeight;
      }

      &:after {
        margin-left: 100%;
      }

      &.last {
        &:before {
          margin-top: 0;
        }

        &:after {
          margin-top: -($linkHeight * 2 - $linkWidth);
        }
      }
    }
  }

  .element {
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;

    @media screen and (max-width: 900px) {
      &.study {
        flex-direction: row-reverse;
        text-align: right;
      }
    }

    img {
      width: 100px;
      height: 100px;
      margin: 5px;
    }

    h2 {
      font-size: 28px;
    }

    h2 span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: #333;
      max-width: 300px;
    }
  }
}
