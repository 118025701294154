@import 'styles/colors.scss';

.Listing {
  header {
    display: flex;
    justify-content: space-around;

    .title {
      min-width: 500px;

      @media screen and (max-width: 900px) {
        min-width: 0;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  h1 {
    text-align: center;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    max-width: 500px;
    padding: 0 15px;
    box-sizing: border-box;
    text-align: center;
  }

  .filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    img {
      height: 20px;
      padding-top: 5px;

      @media screen and (max-width: 900px) {
        border-top: 1px solid rgba(gray, 0.2);
        padding-top: 10px;
        margin-top: 5px;
        padding-right: 10px;
      }
    }

    > div {
      display: flex;
      align-items: flex-start;
      padding-left: 20px;
    }

    ul {
      margin: 5px 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style-type: none;

      @media screen and (max-width: 900px) {
        width: 90%;
        border-top: 1px solid rgba(gray, 0.2);
        padding-top: 10px;
      }
    }

    li {
      margin: 0 2px;
      white-space: nowrap;
      font-size: 14px;

      cursor: pointer;
      border: 1px solid rgba(black, 0);
      color: rgba(black, 0.5);
      padding: 2px 5px;
      transition: 0.4s all;
    }

    li:hover,
    li.active {
      border-radius: 2px;
      border: 1px solid rgba(black, 0.5);
      color: rgba(black, 1);
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .list > div:first-child {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    padding: 30px 0;

    .TeamBox {
      margin: 5px 0;
    }
  }

  .paginator {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    list-style-type: none;
    margin: 10px 0;
    padding: 5px;

    li {
      padding: 0 10px;
      cursor: pointer;
      transition: 0.4s all;

      &:hover {
        transform: scale(1.2);
      }

      border: 1px solid rgba($secondary, 0);
      border-radius: 30px;
    }

    .active {
      border-color: rgba($secondary, 1);
    }

    .disabled {
      opacity: 0.5;
    }

    .next,
    .previous {
      display: block;
      text-align: center;
      color: white;
      width: 10px;
      height: 20px;
      background-color: $primary;
      border-radius: 20px;
    }
  }
}
