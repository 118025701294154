.MentorsList {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .text {
      h2 {
        font-weight: 400;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;

      button {
        margin: 10px;
      }
    }

    button {
      width: 200px;
      white-space: nowrap;
    }

    p {
      max-width: 600px;
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    .filters {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .CitySelector:last-child {
        justify-content: flex-start;
      }

      img {
        height: 20px;
        padding-top: 5px;
      }

      > div {
        display: flex;
        align-items: flex-start;
        padding-left: 20px;
      }

      ul {
        margin: 5px 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        @media screen and (max-width: 900px) {
          flex-wrap: nowrap;
          overflow: auto;
          width: calc(100vw - 50px);
        }
      }

      li {
        margin: 0 5px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        border: 1px solid rgba(black, 0);
        color: rgba(black, 0.9);
        padding: 2px 5px;
        transition: 0.4s all;
      }

      li:hover,
      li.active {
        border: 1px solid rgba(black, 0.5);
        color: rgba(black, 1);
      }
    }
  }

  @-moz-document url-prefix() {
    .filters ul li {
      font-weight: 200;
    }
  }

  .list {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .Person {
      margin: 10px;
    }
  }
}
