@import 'styles/colors.scss';

.Menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;
  
  .logo {
    width: 250px;
    margin-top: 5px;

    @media screen and (max-width: 900px) {
      width: auto;
      height: 40px;
    }
  }

  .mobileMenu {
    height: 20px;
    margin-left: auto;
    margin-right: 15px;
  }

  .links-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;

    a {
      text-decoration: none;
      color: white;
      margin: 0 15px;
      margin-top: 5px;

      @media screen and (max-width: 900px) {
        width: 100%;
        margin: 0;
        padding: 5px 15px 0 15px;
      }

      &:after {
        display: block;
        content: '';

        width: 0;
        height: 1px;
        margin-top: 5px;

        background-color: white;
        transition: 0.4s width;
      }

      &:hover:after,
      &.active:after {
        width: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      position: absolute;
      display: flex;
      flex-direction: column;

      right: 30px;
      top: 60px;
      background-color: white;

      max-height: 0px;
      overflow: hidden;

      align-items: flex-start;
      box-shadow: 0 5px 15px rgba(black, 0.5);

      transition: 0.4s all;
      z-index: 100;

      a {
        color: $primary;
        position: relative;
        z-index: 101;
      }

      &.open {
        max-height: 200px;
        border-bottom: 3px solid $secondary;

        .overlay {
          position: fixed;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
          z-index: 90;
        }
      }
    }
  }
}