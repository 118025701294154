.CitySelector {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    font-size: 14px;
    font-weight: 500;
    padding: 2px;
    margin: 2px;

    opacity: 0.5;
    border: 1px solid rgba(black, 0);
    cursor: pointer;

    transition: 0.4s opacity, 0.4s border;

    &:hover,
    &.active {
      opacity: 1;
      border-color: rgba(black, 0.5);
    }
  }
}
