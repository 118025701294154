.Partners {
  padding: 0 5px;
  box-sizing: border-box;
  // background-color: #E6E6E6;
  background-color: white;

  display: flex;
  flex-wrap: wrap;

  .CitySelector li {
    font-size: 12px;

    &:first-child {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }

  .partnerBlock {
    padding: 5px 30px;
    width: 100%;
    box-sizing: border-box;

    &:first-child img,
    &:nth-child(2) img {
      width: 150px;
      height: 150px;
      
      @media screen and (max-width: 520px) {
        width: 80px;
        height: 80px;
      }
    }

    h2 {
      margin: 10px 0;
      padding: 0 0 5px 0;
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      color: rgba(black, 0.8);
    }

    img {
      margin-right: 10px;
      object-fit: contain;

      /* IE Fix */
      @media screen and (min-width: 0\0) {
        width: 50px;
      }

      @media screen and (max-width: 800px) {
        margin: 10px auto;
        max-height: 100px;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    @media screen and ( max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);

    }

    &.centered {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .regionalPartners {
    .title {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 100%;
    }

    .list {

      img {
        max-height: 80px;
        height: 80px;
        max-width: 130px;
        margin: 0 15px;
      }
    }

    span {
      font-size: 12px;
      padding: 1px 5px;
      margin: 0 2px 2px 2px;

      transition: 0.4s all;

      color: #999;
      border: 1px solid rgba(#B3B3B3, 0);
      cursor: pointer;

      &:hover,
      &.active {
        color: #333;
        border-color: rgba(#B3B3B3, 1)
      }
    }
  }
}
