@import 'styles/colors.scss';

.AboutBox {
  padding-bottom: 30px;
  header {
    display: flex;
    flex-direction: column;

    .winnerCategory {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;

      padding: 0 15px;

      background-color: rgba(247, 147, 30, 0.8);
      color: white;
      font-weight: 400;
      font-size: 30px;
      text-transform: uppercase;

      position: relative;
      margin-top: -50px;
      margin-left: auto;
      z-index: 100;

      @media screen and (max-width: 900px) {
        font-size: 16px;
        padding: 0 10px;
      }
    }

    .teamInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        align-items: flex-end;
      }

      span {
        display: flex;
        align-items: center;
        padding: 5px;
      }

      img {
        height: 30px;
        margin: 0 15px;

        @media screen and (max-width: 900px) {
          margin: 0 10px;
          height: 20px;
        }
      }
    }

    .track {
      padding: 5px 15px 5px 5px;
      margin-left: 15px;

      display: flex;
      align-items: center;
      justify-content: space-around;

      text-align: center;

      background-color: $primary;
      color: white;

      img {
        margin: 0px;
      }
    }
  }

  .details {
    display: flex;
    justify-content: center;

    p {
      padding: 0 15px;
      max-width: 600px;
    }


    @media screen and (max-width: 900px) {
      flex-direction: column;

      li {
        max-width: 300px;
        overflow: hidden;
      }
    }
  }

  .teamDetails {
    padding: 0 10px;

    h1 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    ul {
      margin: 0;
      padding: 0;

      font-size: 16px;
      font-weight: 300;

      list-style-type: none;
    }

    li {
      display: flex;
      align-items: center;
    }

    img {
      height: 15px;
      width: 15px;
      margin: 5px;
    }
  }

  .galleries {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    h1 {
      font-weight: 400;
      font-size: 24px;
    }

    .not-available {
      width: 300px;
      height: 150px;
      line-height: 130px;
      text-align: center;
      font-weight: 300;
      color: rgba(gray, 0.5);
    }
  }
}
