.SmallTimeline {
  background-color: #018B9D;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

  .benefitsSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    color: white;
    text-align: center;
    border-radius: 15px;
    border: 1px solid white;
    margin-top: 20px;

    @media screen and (max-width: 995px) {
      flex-direction: column;
      width: 60%;

    }

    .benefitsColumn{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }


    p{
      margin: 20px;
    }
  }

  >h1 {
    margin: 0;
    padding: 20px;
    text-align: center;
    font-size: 21px;
    font-weight: 200;
    color: white;
  }
  

  
  .items {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 23%;
    background-image: url('./assets/bg.svg');
    width: 100%;
    background-color: #018B9D;
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;

    @media screen and (max-width: 995px) {
      flex-direction: column;
      background: none;
      margin: auto;
    }
  }

  .composed-layout{
    // margin-top: 0.75rem;
    // margin-bottom: 0.75rem;

    h1,
    h2 {
      text-align: center;
      margin: 0;
      padding: 0;
      color: white;
      font-size: 14px;
    }

    h1 {
      text-transform: uppercase;
    }

    h2 {
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }
  }
  .composed-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;

    margin: 0px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 995px) {
      flex-direction: column;
    }

    // .item{
    //   h1{
    //     @media screen and (max-width: 500px) {
    //       font-size: 10px;
    //     }
    //   }
    // }
    h1,
    h2 {
      text-align: center;
      margin: 0;
      padding: 0;
      color: white;
      font-size: 14px;

      
    }

    h1 {
      text-transform: uppercase;
    }

    h2 {
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
      
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    margin: 10px;

    button{
      margin-top:10px;
      padding: 10px 20px;
      border-radius: 15px;
      font-size:16px;
      // style = {{padding:"10px 20px"}}
    }
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }

    h1,
    h2 {
      text-align: center;
      margin: 0;
      padding: 0;
      color: white;
      font-size: 14px;
    }

    h1 {
      text-transform: uppercase;
    }

    h2 {
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }
  }

  .buttonContainer {
    margin: 20px;
  }

  button {
    border: 1px solid white;
  }
}