@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,800&subset=latin-ext');
@import 'styles/colors.scss';

* {
  outline: none;
}

body {
  background-color: $primary;
  transition: 0.4s background-color, 0.4s opacity;
  margin: 0;

  font-family: 'Montserrat', 'Trebuchet MS', sans-serif;
  font-weight: 400;
}

.facebookIntegration {
  display: flex;
  flex-direction: column;
  height: 550px;

  h1 {
    margin: 10px;
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 450px;
    padding: 0 10px;
  }
  .list > div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .fb_iframe_widget {
    flex: 1;
    margin: 0;
    width: 100%;
    height: 100%;

    span,
    span iframe{
      height: 100% !important;
      width: 100% !important;
    }
  }
}

@-moz-document url-prefix() {
  body {
    font-weight: 200;
  }
}

button {
  padding: 10px 25px;

  border: 0;
  border-radius: 15px;
  color: white;
  text-align: center;

  font-family: 'Montserrat', 'Trebuchet MS', sans-serif;
  font-size: 16px;

  background-color: $secondary;
  cursor: pointer;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;

  >div {
    width: 100%;
  }
}

.loader {
  padding: 10px;
  margin: 10px;
  width: 50px;
  height: 50px;

  animation-name: loader;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  from {
    filter: blur(10px);
    transform: scale(2)
  }
  50% {
    filter: blur(0);
    transform: scale(1)
  }
  to {
    filter: blur(10px);
    transform: scale(2)
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  font-size: 0px;
  font-weight: 400;

  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    margin: 30px;

    animation-name: loader;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
}
