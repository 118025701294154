@import "styles/colors.scss";

.Header {
  min-height: 275px;
  background-size: cover;
  background-position: center 25%;
  background-color: $primary;

  position: relative;

  overflow: hidden;
  user-select: none;

  @media screen and (max-width: 900px) {
    min-height: 250px;
  }

  .title{
    
    @media screen and (max-width: 1024px) {
      font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 2rem;
      max-width: 60%;

    }
    @media screen and (max-width: 425px) {
        height: 100%;
        max-width: 100%;  
        padding:20px;
      }
    

    .subtitle{
      font-size: 1.5rem;

      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }
      @media screen and (max-width: 333px) {
        font-size: 0.75rem;
      }
     

    }
  }
  > h2 {
    position: absolute;
    bottom: 30px;

    margin: 0;
    font-weight: 100;
    font-size: 50px;

    backdrop-filter: blur(5px);
    background-color: rgba($secondary, 0.8);
    color: white;

    padding: 5px 15px;
    // max-width: 500px;

    @media screen and (max-width: 900px) {
      // max-width: 280px;
      font-size: 40px;
    }
    @media screen and (max-width: 425px) {
      top: 0px;
   
    }

    animation-name: headerFadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;

    span {
      font-weight: 600;
    }

    img {
      display: block;
      margin: 0 auto;
      max-width: 250px;
      max-height: 100px;
    }
  }

  .RegisterButtonSpan {
    // backdrop-filter: blur(5px);
    position: absolute;
    right: 0;
    bottom: 30px;
    background-color: rgba($secondary, 0.8);
    padding: 10px;

    @media screen and (max-width: 425px) {
      left: 0;
      background-color: transparent;
      padding: 20px;
      bottom: 5px;

    }
  }
}

@keyframes headerFadeIn {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
