.MentorsList {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;

      button {
        margin: 10px;
      }
    }

    button {
      width: 200px;
      white-space: nowrap;
    }

    p {
      max-width: 600px;
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }

  .list {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .Person {
      margin: 10px;
    }
  }

}
